  .allInputs {
    display: flex;
  }

  textarea,
  input {
    padding: .5rem;
    font-size: 16px;
    width: 80%;
    display: block;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  textarea {
    width: 100%;
    height: 80%;
  }

  input:focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }

  input.error {
    border-color: orangered;
  }

  /* label {
    font-weight: bold;
    display: block;
    margin-top: 1rem;
    margin-bottom: .25rem;
  } */

  .input-feedback {
    font-size: 15px;
    font-style: italic;
    color: rgb(255, 119, 0);
    margin-top: .25rem;
  }

  button {
    max-width: 150px;
    margin: 20px 0;
    padding: 12px 20px;
    border-style: none;
    border-radius: 5px;
    background-color: #00aeef;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
  }

  .buttonFooter {
    display: flex;
    justify-content: space-between;
  }

  button:disabled {
    opacity: .5;
    cursor: not-allowed !important;
  }

  button+button {
    margin-left: 2rem;
  }

  button.outline {
    background-color: #eee;
    border: 1px solid #aaa;
    color: #555;
  }

  @media screen and (max-width: 600px) {
    .allInputs {
      flex-direction: column;
    }
  }