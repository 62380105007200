.forceWhite {
  color: white !important;
}

.forceBlack {
  color: black !important;
}

.italic {
  font-style: italic;
}

/*
FAB = 0a9fe0
IO = 1c6dad
VA = 912d89
LE = ca2c80
NT = ef2a7a
*/

/*
fa to bio = background: linear-gradient(to bottom right, #0a9fe0, #1c6dad);
*/
.HomeGradient {
  background: linear-gradient(to right, #0a9fe0, #6251a2);
  /* background: linear-gradient(to bottom right,#4273be, #753f91); */
  /* background: linear-gradient(to bottom right, #4273be, #cc2c7f); */
  /*
  blues: 1c6bab 
  purple:415aa2
  */
}

.ResumeGradient {
  background: linear-gradient(to right, #4273be, #753f91);
  /* background: linear-gradient(to bottom right,#6a4696, #486fbb); */
  /*
  blues:  3486ce
  4b6bb7
  4273be
  */
}

.PortfolioGradient {
  background: linear-gradient(to right, #544898, #9b2d88);
  /* background: linear-gradient(to bottom right, #045597, #00aeef); */
  /* background: linear-gradient(to bottom right, #1c6bab, #9b2d88); */
}

.ContactGradient {
  background: linear-gradient(to right, #862d8b, #cc2c7f);
}

.App {
  font-size: 1.2em;
  font-weight: 100;
  color: white;
  letter-spacing: 0.05em;
}

.force-full-view {
  height: 90vh;
}

.center {
  text-align: center;
}

.mobile {
  padding: 0em;
}

.parragraph {
  display: flex;
  flex-direction: row;
}

li {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  list-style: disc;
  margin-left: 1.5rem;
}

ul {
  margin: 1rem;
}

strong {
  color: black;
}

.navbar {
  padding-right: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.navbar-burger {
  align-self: center;
  color: black;
  position: absolute;
  right: 1%;

}

span.navbar-item {
  color: yellow;
}

a.navbar-item:hover {
  background-color: black !important;
  color: yellow !important;
  text-decoration: underline;
}

.navbar-item {
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.logo {
  padding-top: 0rem;
  padding-left: 5rem;
  max-height: 80px;
  min-width: 200px;
}

.underline {
  text-decoration: underline;
  padding-bottom: 0.3rem;
}

.largeFont {
  font-size: 1.5rem;
}

.footer {
  background: black;
  padding-left: 5rem;
  padding-right: 5rem;

  & span {
    text-wrap: nowrap;
    font-family: monospace;
  }
}

.footer-column {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.column-title {
  font-weight: 900;
  padding-top: 10px;
}

.footer-signature {
  position: absolute;
  bottom: 10;
  left: 4;
  color: orange;
  font-weight: 600;
  font-family: monospace;
  font-size: 0.8rem;
  font-weight: 200;
  letter-spacing: 0.01px;
  background-color: black;
  padding-left: 40px;

  >span {
    text-wrap: wrap;
  }
}

@media screen and (max-width: 1400px) {
  .view {
    padding: 5em;
    padding-bottom: 10em;
  }
}

@media screen and (max-width: 1023px) {
  .navbar {
    padding-left: 4rem;
    padding-right: 3rem;
  }

  .navbar-menu {
    position: absolute;
    right: 16px;
    top: 78px;
    background-color: black;
  }

  .logo {
    padding-left: 0rem;
    padding-top: 0rem;
  }

  .navbar-end {
    margin-top: 30px;
  }
}

@media screen and (max-width: 950px) {
  .footer {
    background-color: black;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 750px) {
  .navbar-menu {
    background-color: black;
  }

  .logo {
    padding-left: 0rem;
    padding-top: 0rem;
  }
}

@media screen and (max-width: 450px) {
  .view {
    padding: 0em;
    padding-bottom: 10em;
  }

  .navbar-menu {
    background-color: black;
  }

  .logo {
    margin-left: -2rem;
    padding-right: 2rem;
    padding-left: 0rem;
    padding-top: 0rem;
  }

  ul {
    margin-left: 0rem;
  }
}